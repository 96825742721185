.container {
    display: flex;
    justify-content: left;
    border-style: solid;
    border-color: #e4bf51;
    background-color: #82abc8;
    border-width: 10px;
    border-radius: 10px;
    margin-top: 80px;
    margin-left: 150px;
    margin-right: auto;
    width: 60%;
    flex-direction: row;
}

.container-image {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}

.container-image img {
    margin-right: 20px;
    padding-bottom: 25px;
    height: 260px;
    width: 260px;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.container-image h1 {
    margin-top: 30px;
    font-size: small;
    margin-left: 10px;
}
