.form-container {
    border-style: solid;
    border-color: #e4bf51;
    background-color: #82abc8;
    border-width: 10px;
    border-radius: 10px;
    margin: 40px auto auto;
    width: 30%;

    .form-input {
        margin: 25px;

        .form-icon-male {
            fill: blue;
        }

        .form-icon-female {
            fill: purple;
        }

        .form-button {
            color: #fff;
            font-weight: bold;
            background-color: #f4292c;
            border-radius: 5px;
            margin: 20px auto auto;
            width: 60%;
            height: 44px;
        }
    }
}

label {
    display: inline-block;
}

label:not(.form-gender) {
    margin-bottom: 15px;
}