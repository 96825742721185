.navbar {
    width: 100%;
    height: 160px;
    background: #f4292c;
    border-bottom: 8px solid #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    margin-left: 80px;
    margin-top: 8px;
}

.logo-container img {
    width: 200px;
    height: 150px;
}

.links-container {
    margin-right: 80px;
    display: flex;
    width: 50%;
    justify-content: space-evenly;
}

.link a {
    text-decoration: none;
    font-size: 23px;
    font-weight: bold;
    color: black;
    
    padding: 5px; /* Adiciona algum espaçamento interno para tornar a borda visível */
    text-decoration: none; /* Remove o sublinhado padrão dos links */
    
}

.link a:hover {
    text-decoration: none;
    color: #e9bd31;
    border: 2px solid #e9bd31; /* Uma borda sólida de 1 pixel preta */
    padding: 5px; /* Adiciona algum espaçamento interno para tornar a borda visível */
    text-decoration: none; /* Remove o sublinhado padrão dos links */
    background-color: #f51a1e;
}
